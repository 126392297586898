import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{`, the leading analytics platform for conversational interfaces, announces an integration with `}<a parentName="p" {...{
        "href": "https://www.twilio.com/autopilot"
      }}>{`Twilio’s AutoPilot`}</a>{`.`}</p>
    <p>{`Twilio developers can now seamlessly add Dashbot support to their chatbots via a webhook integration.`}</p>
    <h2>{`Integration is easy`}</h2>
    <p>{`Developers can be up and running in minutes. Simply sign up for a Dashbot account, add a chatbot, and include our webhook in the settings on Twilio.`}</p>
    <p>{`Follow the `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/docs/twilio/webhook/"
      }}>{`full instructions here`}</a>{` and see the screen shot below.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2020/06/22142614/twilio-integration-1024x777.png",
        "alt": null
      }}></img>{` Data is sent to Dashbot in real time. Once the integration is complete, developers can immediately see the messages and interactions in the Live Firehose and `}<a parentName="p" {...{
        "href": "/tour/conversational-analytics/#transcripts"
      }}>{`Transcripts`}</a>{` in the `}<a parentName="p" {...{
        "href": "/tour"
      }}>{`reports`}</a>{`.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{` is an analytics platform for conversational interfaces that enables enterprises to increase engagement, satisfaction, and conversion through actionable insights and tools.`}</p>
    <p>{`In addition to traditional metrics like engagement and retention, we provide chatbot specific analytics including NLP response effectiveness, sentiment analysis, conversation flows, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our NLP Optimization tools, Phrase Clustering, NLP Assessments, and Live Person Take Over of chat sessions.`}</p>
    <p>{`We support Alexa, Google Assistant, Web Chat, SMS, Facebook Messenger, Slack, Twitter, Bixby, and any other conversational interface.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      